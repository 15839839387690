<template>
    <div class="recordQueryBox">
        <div class="title">全文检索</div>
        <div class="flex_l_c srhBox">
            <SelectTree class="selectBox" ref="menuParentTree"
                        :props="defaultProps"
                        :data="classifyList"
                        :value="searchForm.archivesBasicDataId"
                        :clearable="false"
                        :accordion="true"
                        @getValue="(value) => {searchForm.archivesBasicDataId=value}"/>
            <el-input class="flex1 el-input__inner2" v-model="searchForm.keyWord" clearable maxlength="50" placeholder="请输入关键词进行检索，多个关键词用空格分开（限50字）" @keyup.enter.native="search()"></el-input>
            <el-button type="primary" @click="search()">检索</el-button>
        </div>
    </div>
</template>

<script>
    import SelectTree from '@/components/treeSelect/treeSelect.vue'
    export default {
        components: {
            SelectTree
        },
        data() {
            return {
                defaultProps: {
                    value: 'id',
                    label: 'archivesBasicName',
                    children: 'children',
                },
                classifyList: [],
                searchForm: {
                    archivesBasicDataId: '',
                    keyWord: ''
                },
            }
        },
        mounted() {
            this.getClassifyList()
        },
        methods: {
            // 检索
            search() {
                this.$router.push({path: '/record/query/fulltextResult', query: this.searchForm})
            },
            // 获取档案分类
            getClassifyList() {
                this.$axios(this.api.record.selectArchivesBasicData).then((res) => {
                    if (res.status) {
                        this.classifyList = res.data
                        this.classifyList.unshift({
                            archivesBasicName: '全部',
                            id: '1',
                            children: []
                        })
                        this.$nextTick(() => {
                            this.searchForm.archivesBasicDataId = '1'
                        })
                    }
                })
            },
        },
    }
</script>

<style scoped>
    .recordQueryBox {
        margin: -10px;
        background: url('~@/assets/img/retrievalBg.png') no-repeat;
        background-size: 100% 100%;
        height: calc(100% + 20px) !important;
    }
</style>
